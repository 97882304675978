var user;

$(function() {
  setTimeout(function(){
    $('body').fadeIn();
  },500);

  window.onbeforeunload = function() {
      // IE用。ここは空でOKです
  };
  window.onunload = function() {
      // IE以外用。ここは空でOKです
  };
  window.addEventListener("pageshow", function(event){
    if (event.persisted) {
      // ここにキャッシュ有効時の処理を書く
      window.location.reload();
    }
  });

});


$(window).on('load', function() {

  const api = new AjaxComponent();
  // GETリクエスト
  api.get('/user')
    .then(data => {
      user = data;

      $('.l-page-header__name').text(data.userName);
      
      // 選択されたサイドバーを挿入
      $("#sidebar").html(sidebarTemplates[user.role]);
    
      // data-current-class属性から-currentクラスを追加するリンクのクラスを取得
      var currentClass = $("#sidebar").data("current-class");
    
      // 現在のリンクに-currentクラスを追加
      $("#sidebar .l-side-header__link.-" + currentClass).addClass("-current");
    })
    .catch(error => {
      window.location.href = "/login";
    });

  // サイドバーのテンプレート
  var sidebarTemplates = {
    'admin': `
      <nav>
        <ul class="l-side-header__list">
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">店舗管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -store-list" href="/store/list/">店舗一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -store" href="/store/regist/">店舗登録</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">動画管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-list" href="/movie/list/">動画一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie" href="/movie/regist/">動画登録</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-category" href="/movie/category/regist/">動画カテゴリ</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -title" href="">ユーザー管理</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -profile" href="/mypage/">マイページ</a></li>
        </ul>
      </nav>
    `,
    'store': `
      <nav>
        <ul class="l-side-header__list">
          <li class="l-side-header__item"><a class="l-side-header__link -session" href="/session/">アクセス・予約</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -movie-list" href="/store/movie-list/">動画一覧</a></li>
          <li class="l-side-header__item"><a class="l-side-header__link -profile" href="/mypage/">マイページ</a></li>
        </ul>
      </nav>
    `
  };

  // ページヘッダーをロード
  $("#page-header").load('/common/page-header.html', function(response, status, xhr) {
    if (status == "error") {
      console.error("Error loading page header: ", xhr.status, xhr.statusText);
    }

    $('.l-page-header__logout').on('click', function() {
      window.location.href = "https://api.ad.privatesalon-navi.com//logout";
    });
  });

  // サイト名をロード
  $("#sitename").load('/common/sitename.html', function(response, status, xhr) {
    if (status == "error") {
      console.error("Error loading sitename: ", xhr.status, xhr.statusText);
    }
  });

  // // ページネーションをロード
  // $("#pagination").load('/common/pagination.html', function(response, status, xhr) {
  //   if (status == "error") {
  //     console.error("Error loading pagination: ", xhr.status, xhr.statusText);
  //   }
  // });
});